import React from "react";
import { Container, Breadcrumb} from "react-bootstrap";
import {Link} from 'gatsby'
import PageWrapper from "../../components/PageWrapper";
import { Section, Title, Post } from "../../components/Core";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {Helmet} from "react-helmet";
import {StaticImage} from 'gatsby-plugin-image';

function startabusiness() {
    return (
        <>
        <PageWrapper footerDark>
    	<Helmet>
                <meta charSet="utf-8" />
                <title>Classement des banques françaises - quels critères choisir ?</title>
                <meta name="description" content="
              Nous cherchons tous un classement des meilleures banques, mais comment décider les critères importants de notre recherche ? BankBank vous explique tout !" />
        </Helmet>
      	<Header />
      	<Section>
      	<Container style={{maxWidth: 1280}}>
		  <Breadcrumb id='breadyTop'>
			<Breadcrumb.Item> <Link to={`/`} style={{color: 'black'}}>Accueil</Link></Breadcrumb.Item>
			<Breadcrumb.Item> <Link to={`/academie/`} style={{color: 'black'}}>Banque Académie</Link></Breadcrumb.Item>
			<Breadcrumb.Item active id='bready'>Classement des banques françaises – quels critères ?</Breadcrumb.Item>
      	 </Breadcrumb>
        	<Title id='mobileTitle'>Classement des banques françaises – quels critères ?</Title>
			<StaticImage
		  	src='../../images/classement-des-banques-francaise.jpeg'
			width={1200}
			alt='Classement des banques française'
			placeholder='tracedSVG'
			id='pickky'
			/>
			<Post>
		<p>	Le secteur bancaire français est partagé entre plusieurs grandes enseignes de banques de détails. Par conséquent, le classement et la comparaison des banques françaises peut se faire selon des critères quantitatifs mais aussi qualitatifs : le nombre de client, la taille, le statut juridique ou encore le type d’activité.
		</p>
<h2>Classement des banques selon le nombre de clients</h2>
<p>Le nombre de clients est un indicatif sur les parts de marchés dont disposent les banques françaises. Toutefois, ces données sont à prendre avec du recul car incluent généralement les particuliers, les professionnels et les entreprises qui peuvent êtres clients dans plusieurs établissements à la fois.
</p><p>
En tête de liste, dépassant la barre de 20 millions de clients et largement devant la concurrence arrivent le Crédit Mutuel-CIC, le Crédit Agricole et la Caisse d’Épargne. En bas de liste viennent, en toute logique, les toutes nouvelles sur le marché bancaire : les banques en ligne et les néo-banques. En revanche, leur nombre de clients augmente significativement au fil des années. Elles sont accompagnées par les banques traditionnelles comme Axa Banque ou HSBC.
</p>
<h2>Classement des banques selon la taille</h2>
<h3>Le nombre de salariés</h3>
<p>La plupart des banques traditionnelles sont considérées comme des très grandes entreprises puisque leur nombre de collaborateurs dépasse le seuil des 1 000. Les banques en ligne quant à elles se regroupent dans les grandes et moyennes entreprises, la dématérialisation entraînant des effectifs réduits. Il convient de préciser qu’il n’existe pas de corrélation entre le nombre de salariés et le chiffre d’affaires.
</p>
<h3>Le Produit Net Bancaire (PNB)</h3>
<p>Le PNB est un indicateur sur l’activité des banques et représente la différence entre les produits et les charges d’exploitation bancaires. Selon l’Autorité de Contrôle Prudentiel et de Résolution (ACPR), les 6 principaux groupes de banques de détail français ont généré 63,8 milliards d’euros de PNB dont 43,4 milliards pour BNP Paribas, 30,4 milliards pour le Crédit Agricole et 25,3 milliards pour la Société Générale.
</p>
<h3>Les bénéfices réalisés</h3>
<p>En 2016, les bénéfices réalisés par les 6 groupes français s’élèvent à 23,5 milliards d’euros dont 7,7 milliards pour BNP Paribas, 4,8 milliards pour le Crédit Agricole et 3,9 pour le groupe BCPE.
</p>
<h3>Le coefficient d’exploitation</h3>
<p>Calculé par la division des charges d’exploitation par le PNB, il permet de mesurer l’efficacité d’un établissement bancaire. Plus il est bas, plus l’exploitation d’une banque est efficace. En 2016, il était de 65,8 % dont 61,3 % pour le Crédit Agricole, 61,7 % pour le Crédit Mutuel-CIC et 65,6 % pour la Société Générale.
</p>
<h2>Classement des banques selon le statut juridique</h2>
<p>Le classement des banques selon leur statut juridique est établit en fonction du type d’actionnariat dont elles dépendent. On en distingue trois principales :
</p><p>
La banque commerciale dont le capital est détenu par des actionnaires extérieurs et privés
</p><p>
La banque coopérative ou mutualiste dont les sociétaires, qui sont à la fois clients et actionnaires, font partie prenante du fonctionnement de l’établissement.
</p><p>
La banque publique, propriété de l’État ou des acteurs publics.
</p>
<h2>Classement des banques selon le type d’activité</h2>
<p>L’activité des établissements bancaires requiert également une variété de typologies:
</p><p>
La banque de dépôt dont l’objectif est la gestion et la commercialisation de produits bancaires. Elle regroupe la banque de détail (traditionnelle ou en ligne) destinée aux particuliers, professionnels et PME-PMI et la banque d’affaire dédiée aux grandes entreprises.
</p><p>
La banque centrale constitue une institution à but non lucratif chargée de la régulation et l’équilibre économique.
</p><p>
La banque d’investissement qui permet aux entreprises et aux investisseurs de se financer ou de placer des liquidités, notamment en bourse.</p>
			</Post>
		</Container>
		</Section>
		<Footer/>
      </PageWrapper>
    </>
    )
}

export default startabusiness
